<template>
  <div>
    <!--  -->
    <div class="listStyle" v-for="(item, index) in columnList" :key="index">
      <div class="flx commonality">
        <div>栏目名称</div>
        <div>{{ item.navName }}</div>
      </div>
      <div class="flx commonality">
        <div>使用范围</div>
        <div>{{ item.useScope == 1 ? "电脑端" : "移动端" }}</div>
      </div>
      <div class="flx commonality">
        <div>栏目图标</div>
        <div>{{ item.navType }}</div>
      </div>
      <div class="flx commonality">
        <div>排序</div>
        <div>{{ item.sort }}</div>
      </div>
      <div class="flx commonality">
        <div>导航显示</div>
        <div><van-switch v-model="item.status" size="16px" /></div>
      </div>
      <div class="flx commonality">
        <van-button type="primary" size="small" @click="compile(item)">
          编辑
        </van-button>
        <van-button type="danger" size="small" @click="deletes(item)">
          删除
        </van-button>
      </div>
      <!-- 如果有子级 -->
      <van-collapse v-if="item.children" v-model="activeName" accordion>
        <van-collapse-item
          v-for="(item, index) in item.children"
          :key="index"
          :title="item.navName"
          name="1"
        >
          <div class="flx commonality">
            <div>栏目名称</div>
            <div>{{ item.navName }}</div>
          </div>
          <div class="flx commonality">
            <div>使用范围</div>
            <div>{{ item.useScope == 1 ? "电脑端" : "移动端" }}</div>
          </div>
          <div class="flx commonality">
            <div>栏目图标</div>
            <div>{{ item.navType }}</div>
          </div>
          <div class="flx commonality">
            <div>排序</div>
            <div>{{ item.sort }}</div>
          </div>
          <div class="flx commonality">
            <div>导航显示</div>
            <div><van-switch v-model="item.status" size="16px" /></div>
          </div>
          <div class="flx commonality">
            <van-button type="primary" size="small" @click="compile(item)">
              编辑
            </van-button>
            <van-button type="danger" size="small" @click="deletes(item)">
              删除
            </van-button>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div class="locationStyle" @click="addData">
      <van-icon
        class="centers"
        style="font-size: 20px; color: #fff"
        name="plus"
      />
    </div>
  </div>
</template>
<script>
  import {
    ref,
    getCurrentInstance,
    onMounted,
    onUpdated,
    provide,
    reactive,
    watch,
    toRefs,
  } from "vue";
  import { Dialog, Toast } from "vant";
  export default {
    setup() {
      const columnList = ref([]);
      const reactiveParams = reactive({
        activeName: "1",
      });
      const { proxy } = getCurrentInstance();
      const columnListclick = async () => {
        let list = await proxy.$Api.get(
          proxy.$consultiveManagementApi.getRoleSwitchInfo,
          {
            c: 1,
          }
        );
        console.log(list, "list列表");
        list.data.data.forEach((item) => {
          console.log(item);
          if (item.status == 1) {
            item.status = true;
          } else {
            item.status = false;
          }
        });
        columnList.value = list.data.data;
      };
      const compile = (item) => {
        proxy.$router.push({
          name: "workflowEmit",
          params: { message: JSON.stringify(columnList.value) },
        });
      };

      const deletes = (item) => {
        Dialog.confirm({
          title: "标题",
          message: "您确定要删除此条信息吗？",
        })
          .then(async () => {
            let list = await proxy.$Api.delete(
              proxy.$generalApi.putSysNavigator + "?id=" + item.id
            );
            if (list.data.code == 200) {
              Toast(res.data.msg);
              columnListclick();
            }
          })
          .catch(async () => {
            // on cancel
          });
      };
      const addData = (item) => {
        // console.log(item);
        proxy.$router.push({
          name: "workflowEmit",
          params: { message: JSON.stringify(columnList.value), add: true },
        });
      };
      onMounted(() => {
        columnListclick();
      });
      return {
        ...toRefs(reactiveParams),
        columnList,
        columnListclick,
        compile,
        deletes,
        addData,
      };
    },
  };
</script>

<style scoped>
  .listStyle {
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 0.2rem;
    padding: 0.2rem;
    margin-top: 0.2rem;
  }
  .commonality {
    margin-top: 0.2rem;
  }
  .locationStyle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #2b85e4;
    position: fixed;
    bottom: 30px;
    right: 10px;
  }
</style>
